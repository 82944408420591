<template>
<!-- 主页面 -->
  <div class="data_bodey">
    <div class="index_nav">
      <ul style="height: 30px; margin-bottom: 0px;">
        <router-link to="/"
                     exact>
          <li class="l_left total_chose_p1 ">实时监测</li>
        </router-link>
        <router-link to="/templatepage2">
          <li class="l_left total_chose_pl">用水协会建设</li>
        </router-link>
        <router-link to="/templatepage3">
          <li class="r_right total_chose_pl">水权分配报告</li>
        </router-link>
        <router-link to="/templatepage4">
          <li class="r_right total_chose_pl">水权交易平台</li>
        </router-link>
      </ul>
      <!-- <div class="clear"></div> -->
    </div>
    <div class="index_tabs">
      <div class="inner"
           style="height: 109%;">
        <!-- <ul style="height: 30px; margin-bottom: 0px;">
        </ul> -->
        <div class="clear"></div>

        <div class="left_cage">
          <div class="dataAllBorder01 cage_cl"
               style="margin-top: 9% !important; height: 24%;">
            <div class="dataAllBorder02 ">
              <online></online>
            </div>
          </div>
          <div class="dataAllBorder01 cage_cl"
               style="margin-top: 1.5% !important; height: 70.5%; position: relative;">
            <div class="dataAllBorder02 ">
              <motor-list @change='change'></motor-list>
            </div>
          </div>
        </div>

        <div class="center_cage">
          <div class="dataAllBorder01 cage_cl"
               style="margin-top: 3.5% !important; height: 72.7%; position: relative;">
            <div class="dataAllBorder02"
                 style="position: relative; overflow: hidden;">

              <!--标题栏-->
              <div class="map_title_box"
                   style="height: 6%;">
                <div class="map_title_innerbox">
                  <div class="map_title">实时地图</div>
                </div>
              </div>
              <bmap @change='change'></bmap>
            </div>
          </div>

          <div class="dataAllBorder01 cage_cl "
               style="margin-top: 0.6% !important; height: 22.1%;">
            <div class="dataAllBorder02  "
                 id="map_title_innerbox">
              <div class="map_title_box">
                <div class="map_title_innerbox">
                  <div class="map_title">机井信息</div>
                </div>
              </div>
              <info ref="info"></info>
            </div>

          </div>
        </div>

        <div class="right_cage">
          <div class="dataAllBorder01 cage_cl"
               style="margin-top: 9% !important; height: 16%">
            <div class="dataAllBorder02"
                 id="cage_cl">
              <weather></weather>
            </div>
          </div>

          <div class="dataAllBorder01 cage_cl check_decrease "
               style="margin-top: 1.5% !important; height: 78.5%; position: relative;">
            <div class="dataAllBorder02 over_hide"
                 style="padding: 1.2%;">
              <value-list ref="value"></value-list>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import '../assets/scss/index.css'
import '../assets/scss/index01.css'
import '../assets/scss/Security_operation.css'
import '../assets/scss/BigData.css'

import Bmap from '../components/Bmap.vue'
import Online from '../components/Online.vue'
import Info from '../components/Info.vue'
import MotorList from '../components/MotorList.vue'
import ValueList from '../components/ValueList.vue'
import Weather from '../components/Weather.vue'
export default {
  components: { Bmap, Online, Info, MotorList, ValueList, Weather },
  methods: {
    change (id) {
      this.$refs['value'].getPoint(id)
      this.$refs['info'].getInfo(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.srcoll {
  overflow-y: auto;
}
</style>