<template>
  <div class="com-container">
    <div id="he-plugin-standard"></div>
  </div>
</template>

<script>
export default {
  mounted () {
    window.WIDGET = {
      "CONFIG": {
        "layout": "1",
        "width": "450",
        "height": "150",
        "background": "1",
        "dataColor": "FFFFFF",
        "city": "CN101100804",
        "key": "8897eedf5c4948f1ba90cf61769b6fce"
      }
    }
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0';
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
</script>

<style lang="scss" >
#he-plugin-standard {
  width: 100% !important;
  height: 100% !important;
  background-size: 100% 100% !important;
  border-radius: 8px !important;
}
</style>