<template>
  <div class="com-container srcoll">
    <span>▌ 实时数据</span>
    <el-col :span="8"
            v-for="item in pointList"
            :key="item.id"
            :v-if="show">
      <el-card shadow="hover">
        <p>{{ item.name }}</p>
        <div class="card-b">
          <span>{{ item.value }}{{ item.unit }}</span>
          <span>{{ item.time | ctime }}</span>
        </div>
      </el-card>
    </el-col>
  </div>
</template>

<script>
export default {
  data () {
    return {
      allData: null,
      pointList: [],
      show: false,
    };
  },
  mounted () {
    this.getPoint("00500221091000022706");
    // this.$usr.init(this.$store.state.username, this.$store.state.token, "https://hub.usr.cn:7100/", "https://api.mp.usr.cn/usrCloud")
  },
  destroyed () {
  },
  methods: {
    async getPoint (id) {
      this.show = false;
      const { data: res } = await this.$axios.post(
        "https://openapi.mp.usr.cn/usrCloud/datadic/getDataPointInfoByDevice",
        JSON.stringify({ deviceIds: [id] })
      );
      const slave = res.data[0].slaves;
      this.pointList = [];
      slave.map((item) => {
        const deviceNo = id;
        const slaveIndex = item.slaveIndex;
        item.iotDataDescription.map((res) => {
          const points = {
            deviceNo: deviceNo,
            slaveIndex: slaveIndex,
            dataPointId: res.id,
            name: res.name,
            unit: res.unit,
          };
          this.pointList.push(points);
        });
      });
      this.getValue(this.pointList);
      // this.getData(id)
    },
    async getValue (arr) {
      const { data: res } = await this.$axios.post(
        "https://openapi.mp.usr.cn/usrCloud/vn/ucloudSdk/getLastDataHistory",
        JSON.stringify({ devDatapoints: arr })
      );
      res.data.list.forEach((element) => {
        this.pointList.forEach((res) => {
          if (element.dataPointId == res.dataPointId) {
            res.value = element.value;
            res.time = element.time;
          }
        });
      });
      this.show = true;
    },
    getData (id) {
      this.$usr.addListener("deviceData", { deviceNos: [id] }, (deviceNo, data) => {
        console.log(data.dataPoints)
        data.dataPoints.forEach((element) => {
          this.pointList.forEach((res) => {
            if (element.variableName == res.name) {
              res.value = element.value;
              res.time = element.timeMs;
            }
          });
          this.$set(this.pointList, 0, this.pointList)
        });
      }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.com-container {
  font-size: 20px;
  color: aliceblue;
}
.srcoll {
    overflow-y: auto;
}
p {
  font-size: 16px;
  color: white;
  margin-bottom: 8px !important;
}
span {
  font-size: 20px;
  color: white;
}
.el-col-8 {
  width: 100%;
}
/deep/.el-card__body {
  padding: 8px;
}
.el-card {
  border: 0px;
  background-color: rgba($color: #000000, $alpha: 0);
  color: white;
  box-shadow: 0 0 2.5vw #237ad4 inset;
  background: linear-gradient(#1359df, #1359df) left top,
    linear-gradient(#1359df, #1359df) left top,
    linear-gradient(#1359df, #1359df) right top,
    linear-gradient(#1359df, #1359df) right top,
    linear-gradient(#1359df, #1359df) left bottom,
    linear-gradient(#1359df, #1359df) left bottom,
    linear-gradient(#1359df, #1359df) right bottom,
    linear-gradient(#1359df, #1359df) right bottom;
  background-repeat: no-repeat;
  background-size: 0.1vw 18vw, 1.5vw 0.1vw;
  margin: 5px;
}
.card-b {
  display: flex;
  justify-content: space-between;
}
</style>
