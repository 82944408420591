<template>
<!-- 水权分配报告页面 -->
  <div class="data_bodey">
    <div class="index_nav">
      <ul style="height: 30px; margin-bottom: 0px;">
        <router-link to="/"
                     exact>
          <li class="l_left total_chose_p1 ">实时监测</li>
        </router-link>
        <router-link to="/templatepage2">
          <li class="l_left total_chose_pl">用水协会建设</li>
        </router-link>
        <router-link to="/templatepage3">
          <li class="r_right total_chose_pl">水权分配报告</li>
        </router-link>
        <router-link to="/templatepage4">
          <li class="r_right total_chose_pl">水权交易平台</li>
        </router-link>
      </ul>
    </div>
    <div class="index_tabs">
      <div class="inner"
           style="height: 100%;">
        <div class="total_chose_box">
          <div style="height: 32px;"></div>
        </div>
        <div class="clear"></div>
        <tab></tab>
      </div>

    </div>
  </div>
</template>

<script>
import '../assets/scss/index.css'
import '../assets/scss/index01.css'
import '../assets/scss/Security_operation.css'
import '../assets/scss/BigData.css'
import Tab from '../components/Tab.vue'
// import TownList from '../components/TownList.vue'
export default {
  components: { Tab },
}
</script>

<style lang="scss" >
.data_left04 {
  width: 78.5% !important;
}
.data_left03 {
  width: 20% !important;
}
</style>