<template>
  <div class="com-container">
    <div class="map"
         ref="map_ref">
      <baidu-map :center="center"
                 class="baidumap"
                 @ready='handler'
                 :scroll-wheel-zoom='true'
                 :zoom='zoom'>
        <!-- name 表示区域(行政区)的名字，例如上海市黄浦区，北京市朝阳区
                strokeWeight 表示区域边界的边框宽度
                strokeColor 表示区域边界的边框颜色
                fillColor 表示区域边界的填充颜色
                fillOpacity 表示区域边界的填充颜色透明度 -->
        <bm-boundary name='万荣'
                     :strokeWeight="2"
                     strokeColor="blue"
                     fillColor="skyblue"
                     :fillOpacity="0.1"></bm-boundary>
        <div v-for="(item,index) in dotList"
             :key="item.id">
          <bm-marker :position="{lng:item.lng, lat:item.lat}"
                     :dragging="false"
                     animation=""
                     @click="infoWindowOpen(index)"
                     :icon='{url:iconUrl.url,size:{width:32,height:32}}'
                     :title="item.name">
            <!-- <bm-label :content="item.dot"
                      :offset="{width: -35, height: 30}" /> -->
            <!-- 弹出框内容,可以添加图片 -->
            <bm-info-window :show="isShow == index"
                            @close="infoWindowClose(index)"
                            @open="infoWindowOpen(index,item.id)">
              <p>{{item.name}}</p>
              <p>负责人员：{{item.guard}}</p>
              <p>联系方式：{{item.phone}}</p>
              <p>地下水位：{{item.level}}米</p>
              <!-- <p>运行状态：运行</p> -->
              <p>记录时间：{{item.time}}</p>
            </bm-info-window>
          </bm-marker>
        </div>
      </baidu-map>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // dotList: null,
      center: { lng: 110.709057, lat: 35.403168 },
      zoom: 12,
      isShow: -1,
      dotList: [],
      iconUrl: { url: require('../assets/img/mark1.png') }
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    infoWindowClose () {
      this.isShow = -1
    },
    infoWindowOpen (id,index) {
      this.isShow = id
      this.zoom = 13
      this.center = {
        lng: this.dotList[id].lng,
        lat: this.dotList[id].lat
      }
      this.$emit('change',index)
    },
    handler ({ map }) {
      let mapStyle = { style: 'midnight' }
      map.setMapStyle(mapStyle)
    },
    async getInfo () {
      const { data: res } = await this.$axios.get('/static/json/map.json')
      this.dotList = res
    }
  }
}
</script>

<style lang="scss" >
.com-container {
  border-radius: 8px;
}
.baidumap {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.map {
  .BMap_bubble_title {
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
    border-bottom: 1px solid gray;
    background-color: #0066b3;
  }
  .BMap_pop {
    width: 400px;
    height: 300px;
  }
  /* 消息内容 */
  .BMap_bubble_content {
    // background-color: rgba($color: #0066b3, $alpha: 1);
    padding-left: 5px;
    // padding-left: 5px;
    // padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    box-shadow: 0 0 2vw #237ad4 inset;
    background: linear-gradient(#1359df, #1359df) left top,
      linear-gradient(#1359df, #1359df) left top,
      linear-gradient(#1359df, #1359df) right top,
      linear-gradient(#1359df, #1359df) right top,
      linear-gradient(#1359df, #1359df) left bottom,
      linear-gradient(#1359df, #1359df) left bottom,
      linear-gradient(#1359df, #1359df) right bottom,
      linear-gradient(#1359df, #1359df) right bottom;
    background-repeat: no-repeat;
    background-size: 0.1vw 18vw, 1.5vw 0.1vw;
    margin: 5px;
    img {
      width: 99%;
      height: 90%;
      margin-right: 5px;
    }
    p {
      width: 280px;
      margin-left: 10px !important ;
      font-size: 18px !important;
    }
  }
  /* 内容 */
  .BMap_pop div:nth-child(9) {
    top: 35px !important;

    // border-radius: 7px;
  }
  /* 左上角删除按键 */
  .BMap_pop img {
    top: 43px !important;
    left: 300px !important;
  }
  .BMap_top {
    display: none;
  }
  .BMap_bottom {
    display: none;
  }
  .BMap_center {
    display: none;
  }
  /* 隐藏边角 */
  .BMap_pop div:nth-child(1) div {
    display: none;
  }
  .BMap_pop div:nth-child(3) {
    display: none;
  }
  .BMap_pop div:nth-child(7) {
    display: none;
  }
  .BMap_pop div:nth-child(5) {
    display: none;
  }
  .BMap_pop div:nth-child(8) {
    display: none;
  }
}
</style>

