<template>
  <div class="com-container">
        <div class="com-chart" ref="chart_ref"></div>
    </div>
</template>

<script>
import mqtt from "mqtt";
var client;
const options = {
  port: 8083,
  clean: true, // 保留会话
  connectTimeout: 4000, // 超时时间
  reconnectPeriod: 4000, // 重连时间间隔
  clientId: 'wswrxwhxymx123',
  username: '',
  password: '',
};
client = mqtt.connect('ws://119.45.236.228/mqtt', options);

export default {
  data () {
    return {
      content: '',
      date:null,
      arr: [1, 2, 3, 4, 5,6,7,8,9],
      dateArr:[1,2,3,4,5,6,7,8,9],
      chartInstance:null,
    }
  },
  created () {

  },
  mounted () {
    this.initChar()
    this.myMqtt()
  },
  methods: {
    initChar(){
        this.chartInstance = this.$echarts.init(this.$refs.chart_ref,'chalk')
         const setOption = {  
                xAxis: { type: 'category' },
                yAxis: { type: 'value' },
                series: [ 
                    { name: '语文', 
                    type: 'bar', 
 
                    } ]
          }
          this.chartInstance.setOption(setOption)
    },
    updateChart(){
        const dateOption = {             
                xAxis: {            
                    data: this.dateArr
                },
                series: [{
                    data: this.arr
                }]
           }
        this.chartInstance.setOption(dateOption)
    },
    myMqtt () {
      // 连接mqtt
      client.on('connect', () => {
        console.log('连接成功')
        client.subscribe('Taichi-Maker-Pub-8C:AA:B5:0D:5E:CD', { qos: 0 }, (err) => {
          console.log(err)
        })
      })
      // 接收推送消息
      client.on('message', (topic, message) => {
        console.log(topic)
        console.log('接收推送信息：', message.toString())
        this.content = message.toString()
        this.date = new Date().getDate()
        this.dateArr.push(this.date)
        this.dateArr.shift()
        this.arr.push(parseInt(message.toString()))
        this.arr.shift()
        this.updateChart()
      })
      // 断开发起重连
      client.on('reconnect', (error) => {
        console.log('正在重连...', error)
      })
      // 链接异常处理
      client.on('error', (error) => {
        console.log('连接失败...', error)
      })
    },

  }
}
</script>

<style lang="scss" scoped>
</style>