<template>
  <div class="com-container">
    <div class="data_left03">
      <div class="dataAllNo01 maginS01">
        <div class="dataAllBorder01">
          <div class="dataAllBorder02 srcoll">
            <el-row class="tac">
              <el-col>
                <div class="motor-title">
                  <span>▌ 水权分配列表</span>
                </div>
                <el-menu default-active="1"
                         class="el-menu-vertical-demo"
                         background-color="transparent"
                         text-color="#fff">
                  <el-submenu index="1">
                    <template slot="title">
                      <span>万荣县一级水权分配列表</span>
                    </template>
                    <el-menu-item index="1-1"
                                  @click="change(0)">P=50%</el-menu-item>
                    <el-menu-item index="1-2"
                                  @click="change(1)">P=75%</el-menu-item>
                    <el-menu-item index="1-3" 
                                  @click="change(2)">P=95%</el-menu-item>
                  </el-submenu>
                  <el-submenu index="2">
                    <template slot="title">
                      <span>万荣县二级水权分配列表</span>
                    </template>
                    <el-menu-item index="2-1" @click="change(3)">P=50%</el-menu-item>
                    <el-menu-item index="2-2" @click="change(4)">P=75%</el-menu-item>
                    <el-menu-item index="2-3" @click="change(5)">P=95%</el-menu-item>
                  </el-submenu>
                  <el-submenu index="3">
                    <template slot="title">
                      <span>万荣县乡村水权分配列表</span>
                    </template>
                    <el-submenu index="1-4">
                      <template slot="title">南张乡</template>
                      <el-menu-item index="1-4-1"
                                    @click='getNz(6)'>东苏冯村</el-menu-item>
                      <el-menu-item index="1-4-2"
                                    @click='getNz(7)'>西苏冯村</el-menu-item>
                    </el-submenu>
                    <el-submenu index="1-5">
                      <template slot="title">荣河镇</template>
                      <el-menu-item index="1-5-1"  @click='getNz(8)'>周王村</el-menu-item>
                      <el-menu-item index="1-5-2"  @click='getNz(9)'>荣河村</el-menu-item>
                      <el-menu-item index="1-5-3"  @click='getNz(10)'>马家庄</el-menu-item>
                      <el-menu-item index="1-5-4"  @click='getNz(11)'>南里庄</el-menu-item>
                      <el-menu-item index="1-5-5"  @click='getNz(12)'>北里庄</el-menu-item>
                      <el-menu-item index="1-5-6"  @click='getNz(13)'>刘村</el-menu-item>
                      <el-menu-item index="1-5-7"  @click='getNz(14)'>上朝村</el-menu-item>
                      <el-menu-item index="1-5-8"  @click='getNz(15)'>坑西村</el-menu-item>
                      <el-menu-item index="1-5-9"  @click='getNz(16)'>北扬村</el-menu-item>
                      <el-menu-item index="1-5-10"  @click='getNz(17)'>庙前村</el-menu-item>
                      <el-menu-item index="1-5-11"  @click='getNz(18)'>南坑东村</el-menu-item>
                      <el-menu-item index="1-5-12"  @click='getNz(19)'>志范村</el-menu-item>
                    </el-submenu>
                  </el-submenu>
                </el-menu>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div class="data_left04">
      <div class="dataAllNo01">
        <div class="dataAllBorder01">
          <div class="dataAllBorder02">
            <el-table :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                      max-height="800"
                      style="width:99.5%;margin:5px;overflow-x:auto"
                      :header-cell-style="{'text-align':'center'}"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.8)"
                      v-show="show">
              <el-table-column label="搜索">
                <template slot="header">
                  <el-input v-model="search"
                            size="mini"
                            placeholder="输入关键字搜索" />
                </template>
                <el-table-column prop="id"
                                 label="编号"
                                 width="150">
                </el-table-column>
                <el-table-column prop="name"
                                 label="姓名"
                                 width="180">
                </el-table-column>
              </el-table-column>
              <el-table-column label="土地面积（亩）">
                <el-table-column prop="role"
                                 label="耕地面积"
                                 width="180">
                </el-table-column>
              </el-table-column>
              <el-table-column label="灌溉定额（m³/亩）">
                <el-table-column label="农田灌溉">
                  <el-table-column label="地下水">
                    <el-table-column prop="gp50"
                                     label="P=50%"
                                     width="150">
                    </el-table-column>
                    <el-table-column prop="gp75"
                                     label="P=75%"
                                     width="150">
                    </el-table-column>
                    <el-table-column prop="gp95"
                                     label="P=95%"
                                     width="150">
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column label="水权（m³）">
                <el-table-column label="农田灌溉">
                  <el-table-column label="地下水">
                    <el-table-column prop="sp50"
                                     label="P=50%"
                                     width="180">
                    </el-table-column>
                    <el-table-column prop="sp75"
                                     label="P=75%"
                                     width="180">
                    </el-table-column>
                    <el-table-column prop="sp95"
                                     label="P=95%"
                                     width="180">
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>

            <el-table :data="tableData"
                      max-height="800"
                      style="width:99.5%;margin:5px;overflow-x:auto"
                      :header-cell-style="{'text-align':'center'}"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-spinner="el-icon-loading"
                      :span-method="spanMethod"
                      element-loading-background="rgba(0, 0, 0, 0.8)"
                      v-show="!show">
              <el-table-column prop="name"
                               label="项目"
                               width="135">
              </el-table-column>
              <el-table-column label="集中供水">
                <el-table-column prop="xf"
                                 label="西范集中供水"
                                 width="105">
                </el-table-column>
                <el-table-column prop="cd"
                                 label="城东集中供水"
                                 width="105">
                </el-table-column>
                <el-table-column prop="rh"
                                 label="荣河集中供水"
                                 width="105">
                </el-table-column>
                <el-table-column prop="lw"
                                 label="里望集中供水"
                                 width="105">
                </el-table-column>
              </el-table-column>
              <el-table-column prop="dd"
                               label="当地地下水"
                               width="100">
              </el-table-column>
              <el-table-column prop="fh"
                               label="汾河口水源地"
                               width="100">
              </el-table-column>
              <el-table-column prop="xfbz"
                               label="西范泵站"
                               width="100">
              </el-table-column>
              <el-table-column prop="bz"
                               label="北赵引黄"
                               width="100">
              </el-table-column>
              <el-table-column prop="jmk"
                               label="夹马口北扩"
                               width="100">
              </el-table-column>
              <el-table-column prop="fn"
                               label="汾南灌区"
                               width="100">
              </el-table-column>
              <el-table-column prop="hh"
                               label="黄河滩地下水"
                               width="100">
              </el-table-column>
              <el-table-column prop="zs"
                               label="中水"
                               width="100">
              </el-table-column>
              <el-table-column prop="hj"
                               label="合计"
                               width="135">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
  data () {
    return {
      tableData: [
      ],
      fileList: [],
      nzList: [
        { url: '/static/exl/1-p50.xls' },
        { url: '/static/exl/1-p75.xls' },
        { url: '/static/exl/1-p95.xls' },
        { url: '/static/exl/2-p50.xls' },
        { url: '/static/exl/2-p75.xls' },
        { url: '/static/exl/2-p95.xls' },
        { url: '/static/exl/dsf.xls' },
        { url: '/static/exl/xsf.xls' },
        { url: '/static/exl/zw.xls' },
        { url: '/static/exl/rh.xls' },
        { url: '/static/exl/mjz.xls' },
        { url: '/static/exl/nlz.xls' },
        { url: '/static/exl/blz.xls' },
        { url: '/static/exl/lc.xls' },
        { url: '/static/exl/sc.xls' },
        { url: '/static/exl/kx.xls' },
        { url: '/static/exl/by.xls' },
        { url: '/static/exl/mq.xls' },
        { url: '/static/exl/nkd.xls' },
        { url: '/static/exl/zf.xls' },
      ],
      // exlUrl: '/static/exl/dsf.xls',
      search: '',
      loading: false,
      show: true
    }
  },
  mounted () {
    // this.readExl(this.exlUrl)
    // this.getFiles()
  },
  methods: {
    // getFiles () {
    //   const files = require.context('../../public/static/exl', true, /$/).keys();
    //   console.log(files)
    // },
    async readExl (url) {
      this.loading = true
      var data = await this.$axios.get(url, { responseType: 'arraybuffer' })
      var wb = XLSX.read(data.data, { type: "array" })
      let ws = wb.Sheets[wb.SheetNames[0]]
      this.tableData = XLSX.utils.sheet_to_json(ws)
      this.loading = false
    },
    getNz (id) {
      this.show = true
      const url = this.nzList[id].url
      this.readExl(url)
    },
    change (id) {
      this.show = false
      const url = this.nzList[id].url
      this.readExl(url)
    },
    spanMethod ({ rowIndex, columnIndex }) {
      if (rowIndex == this.tableData.length - 1 || rowIndex == this.tableData.length - 2) { //倒数第一行和倒数第二行
        if (columnIndex === 1) {   //第二列
          return [1, 5];           //合并2-6列
        } else if (columnIndex === 2 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5) { //把3,4,5,6列删除掉，否则数据会往后退
          return [0, 0];
        }
      }
    },
  },
}
</script>

<style lang="scss" >
.el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
.srcoll {
    overflow-y: auto;
}
// /* 去掉中间数据的分割线 */
// .el-table__row > td {
//   border: none;
// }
// /* 去掉上面的线 */
// .el-table th.is-leaf {
//   border: none;
// }
// /* 去掉最下面的那一条线 */
// .el-table::before {
//   height: 0px;
// }
// //透明化行、单元格
.el-table th {
  background-color: #45797b33 !important;
  color: white;
  font-size: 16px;
  // text-align: center;
  // border: none;
}
.el-table tr,
.el-table td {
  background-color: transparent !important;
  color: white;
  font-size: 15px;
}
// //hover时样式
.el-table tbody tr:hover > td {
  background-color: #367f7f78 !important;
}
.com-container {
  font-size: 20px;
  color: rgb(250, 243, 243);
}
.motor-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-row {
  margin: 5px;
  .el-col-20 {
    width: 100%;
  }
}
.el-menu {
  border-right: solid 0px #e6e6e6;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 8px;
  span {
    font-size: 18px;
  }
  li {
    font-size: 16px;
  }
}

.el-menu-item {
  font-size: 20px;
  height: 45px;
  line-height: 45px;
  // color: #666666;
  transition: none !important;
}
//设置选中el-menu-item时的样式
.el-menu-item:hover {
  border-left: #33a2ef solid 6px !important;
  background-color: rgba($color: #000000, $alpha: 0.3) !important;
  color: #38b2ff !important;
  //less语法，实现鼠标悬停时icon变色
  i {
    color: #38b2ff;
  }
}
.el-menu-item.is-active {
  border-left: #33a2ef solid 6px !important;
  background-color: rgba($color: #000000, $alpha: 0.3) !important;
  color: #38b2ff !important;
}
</style>