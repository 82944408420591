import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'vue-cookies'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'chalk',
    token:Cookie.get('token'),
    username:Cookie.get('username'),
    uid:Cookie.get('uid')
  },
  mutations: {
    changeTheme (state) { 
        if (state.theme === 'chalk') { 
            state.theme = 'vintage' 
        } else { 
            state.theme = 'chalk' } 
    },
    getToken(state,obj){
      state.token = obj.token
      state.username = obj.name
      state.uid = obj.uid
      Cookie.set('token',obj.token,'120min')
      Cookie.set('username',obj.name,'120min')
      Cookie.set('uid',obj.uid,'120min')
    },
    delToken(state){
      state.token = ''
      state.username = ''
      state.uid = ''
      Cookie.remove('token')
      Cookie.remove('username')
      Cookie.remove('uid')
    }
  },
  actions: {
  },
  modules: {
  }
})
