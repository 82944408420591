<template>
  <div class="com-container">
    <el-table :data="content"
              max-height="800"
              style="width:99.5%;margin:5px;overflow-x:auto"
              :header-cell-style="{'text-align':'center'}">
      <el-table-column prop="id"
                       label="编号"
                       width="150">
      </el-table-column>
      <el-table-column prop="dot"
                       label="姓名"
                       width="180">
      </el-table-column>
      <el-table-column label="土地面积（亩）">
        <el-table-column prop="zone"
                         label="耕地面积"
                         width="180">
        </el-table-column>
      </el-table-column>
      <el-table-column label="灌溉定额（m³/亩）">
        <el-table-column label="农田灌溉">
          <el-table-column label="地下水">
            <el-table-column prop="card"
                             label="P=50%"
                             width="150">
            </el-table-column>
            <el-table-column prop="name"
                             label="P=75%"
                             width="150">
            </el-table-column>
            <el-table-column prop="phone"
                             label="P=95%"
                             width="150">
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table-column>
      <el-table-column label="水权（m³）">
        <el-table-column label="农田灌溉">
          <el-table-column label="地下水">
            <el-table-column prop="lng"
                             label="P=50%"
                             width="180">
            </el-table-column>
            <el-table-column prop="lat"
                             label="P=75%"
                             width="180">
            </el-table-column>
            <!-- <el-table-column prop="sp95"
                                     label="P=95%"
                                     width="180">
                    </el-table-column> -->
          </el-table-column>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
  data () {
    return {
      content: null,
      exlUrl: '/static/exl/test.xls' //放在public目录下的文件可以直接访问
    }
  },
  mounted () {
    this.readExl(this.exlUrl)
  },
  methods: {
    async readExl (url) {
      var data = await this.$axios.get(url, { responseType: 'arraybuffer' })
      var wb = XLSX.read(data.data, { type: "array" })
      let ws = wb.Sheets[wb.SheetNames[0]]
      this.content = XLSX.utils.sheet_to_json(ws)
    }
  }
}
</script>

<style lang="scss" scoped>
.com-container {
  border-radius: 20px;
}
</style>