<template>
  <div class="login">
    <div class="log">
      <img :src="url"
           alt="">
      <div class="form">
        <h2>欢迎使用农业水价改革展示平台</h2>
        <el-form :model="ruleForm"
                 status-icon
                 ref="ruleForm"
                 class="demo-ruleForm">
          <el-form-item
            prop="account"
            :rules="[
              { required: true, message: '账号不能为空'},
              { type: 'number', message: '账号格式错误'}
            ]">
            <el-input v-model.number="ruleForm.account"
                      placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password"
                      v-model="ruleForm.password"
                      autocomplete="off"
                      placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="medium"
                       @click="submit()">登录</el-button>
            <!-- <el-button>重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      ruleForm: {
        account: '',
        password: ''
      },
      url: require('../assets/img/log01.png')
    }
  },
  methods: {
    async submit () {
      this.ruleForm.password = this.$md5(this.ruleForm.password)
      const data = await this.$axios.post('https://openapi.mp.usr.cn/usrCloud/user/login', this.ruleForm)
      if (data.data.status == 0) {
        const obj = { token: data.data.data.token, name: data.data.data.account, uid: data.data.data.uid }
        this.$store.commit('getToken', obj)
        this.$router.push({ name: 'templatepage' })
      } else {
        this.$message.error('账号或密码错误');
        this.ruleForm = {}
      }
    }
  }

}
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  display: inline-block;
}
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/bg04.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  .log {
    background-color: white;
    border-radius: 8px;
    height: 450px;
    width: 70%;
    min-width: 1150px;
    display: flex;
    img {
      height: 410px;
      width: 650px;
      padding: 20px;
    }
    .form {
      width: 450px;
      height: 410px;
      padding: 80px;
      h2 {
        padding: 20px;
        text-align: center;
      }
      .demo-ruleForm {
        padding: 20px;
        .el-button {
          width: 100%;
        }
      }
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
  }
}
</style>