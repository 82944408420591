import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.$axios = axios

axios.interceptors.request.use(
  config=>{
    if(store.state.token){
      config.headers.common['token']=store.state.token
    }
    return config
  },
  err=>{
    console.log(err)
  }
)
axios.defaults.headers.post['Content-Type']='application/json'




import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import md5 from 'js-md5'
Vue.prototype.$md5 = md5


import './assets/css/global.scss'
import './assets/font/iconfont.css'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'bpPH8XRSLLN4apG0jIZWWGs2DhoijLex'
})

// 将全局的echarts对象挂在到全局
Vue.prototype.$echarts = window.echarts

// 引入时间组件，设置时间格式过滤器
import moment from 'moment'
Vue.filter('ctime', function (data, timeset = 'YYYY-MM-DD HH:mm:ss') {
  return moment(data).format(timeset)
})
Vue.prototype.$usr = window.jssdk


Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  if (to.meta.requireAuth) {
    if (store.state.token) {
      next()
    } else {
      next({name:'log',query:{backUrl:to.fullPath}})
    }
  } else {
    (next())
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
