<template>
  <div class="com-container">
      <div class="title" :style="comStyle"> 
          <span >{{'▎'+ title }}</span>
          <span class="iconfont title-icon" @click="showChoice = !showChoice" :style="comStyle">&#xe6eb;</span> 
          <div class="select-con" v-if="showChoice" :style="marginStyle" > 
              <div class="select-item" v-for="item in selectTypes" :key="item.key" @click="handleSelect(item.key)"> 
                  {{ item.text }} 
              </div> 
          </div>
        </div>
        <div class="com-chart" ref="trend_ref"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return {
            chartInstance:null,
            allData:null,
            dataType: 'map',
            showChoice: false,
            titleFontSize: 0
        }
    },
    mounted(){
        this.initChart()
        this.getData()
        //监听浏览器大小，发生变化时调用方法
        window.addEventListener('resize', this.screenAdapter)
        //在页面加载时，主动进行屏幕适配
        this.screenAdapter()
    },
    destroyed(){
        //在组件销毁的时候，清除定时器
        // clearInterval(this.timeId) 
        // 在组件销毁的时候, 需要将监听器取消掉 
        // window.removeEventListener('resize', this.screenAdapter)
    },
    methods:{
       //初始化图表对象
       initChart(){
           //第二个参数即时设置主题
          this.chartInstance = this.$echarts.init(this.$refs.trend_ref,this.theme)
          //拆分option，option可进行多次设置
          const initOption = {
               xAxis: { type: 'category', boundaryGap: false },
               yAxis: { type: 'value' },
               grid: { top: '35%', left: '3%', right: '4%', bottom: '1%', containLabel: true },
               tooltip: { trigger: 'axis' },
               legend: { top: '15%', icon: 'circle', left: 20 },
          }
          this.chartInstance.setOption(initOption)
          //对图表对象进行鼠标监听
        //   this.chartInstance.on('mouseover',()=>{
        //       clearInterval(this.timeId)
        //   })
        //   this.chartInstance.on('mouseout',()=>{
        //       this.startInterval()
        //   })
       },
       //获取服务器数据
       getData(){
           this.$axios.get('/static/data/trend.json').then((ret)=>{
                this.allData = ret.data
                this.updataChart()
           })
       },
       //更新图表
       updataChart(){
           // x轴的数据 
        //    console.log(this.allData)
           const timeArrs = this.allData.common.month 
        // y轴的数据, 暂时先取出map这个节点的数据 
        // map代表地区销量趋势 // seller代表商家销量趋势 
        // commodity代表商品销量趋势 
        //    const valueArrs = this.allData.map.data 
           const valueArrs = this.allData[this.dataType].data
        //半透明的颜色
           const colorArr1 = [ 
               'rgba(11, 168, 44, 0.5)', 
               'rgba(44, 110, 255, 0.5)', 
               'rgba(22, 242, 217, 0.5)', 
               'rgba(254, 33, 30, 0.5)', 
               'rgba(250, 105, 0, 0.5)' ]
        // 全透明的颜色值 
           const colorArr2 = [ 
               'rgba(11, 168, 44, 0)', 
               'rgba(44, 110, 255, 0)', 
               'rgba(22, 242, 217, 0)', 
               'rgba(254, 33, 30, 0)', 
               'rgba(250, 105, 0, 0)' ]
        // 图表数据, 一个图表中显示5条折线图 
           const seriesArr = valueArrs.map((item,index) => { 
               return { type: 'line', // 折线图 
                        name: item.name, 
                        data: item.data,
                        areaStyle: { color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [ 
                            { offset: 0, color: colorArr1[index] },
                            { offset: 1, color: colorArr2[index] } ]) },
                        stack: this.dataType } 
                        })
        // 准备图例数据, 它需要和series下的每个对象的name属性保持一致 
           const legendArr = valueArrs.map(item => { return item.name })
        
           const dataOption = { 
               xAxis: { data: timeArrs },
               legend: { data: legendArr },
               series: seriesArr 
               }
           this.chartInstance.setOption(dataOption)
       },
       handleSelect (key) { 
           this.dataType = key 
           this.updataChart()
           this.showChoice = false 
        },
       screenAdapter(){
           //获取容器宽度，调整字体大小
           this.titleFontSize = this.$refs.trend_ref.offsetWidth / 100 * 3.6
           const adapterOption = { 
               legend: { itemWidth: this.titleFontSize, 
                    itemHeight: this.titleFontSize, 
                    itemGap: this.titleFontSize, 
                    textStyle: { fontSize: this.titleFontSize / 2 } 
               } 
            }
           this.chartInstance.setOption(adapterOption) 
           this.chartInstance.resize() 
       } 
    },
    computed: { 
        selectTypes () { 
            if (!this.allData || ! this.allData.type) { 
                return [] } else { 
                    return this.allData.type.filter(item => { return item.key !== this.dataType }) 
                    } 
        },
        title () { 
            if (!this.allData) { 
                return '' } else { 
                    return this.allData[this.dataType].title } 
        },
        comStyle () { 
            return { fontSize: this.titleFontSize + 'px' } 
        },
        marginStyle () { 
            return { marginLeft: this.titleFontSize + 'px' } 
        },
        ...mapState(['theme']) 
    },
    watch: { 
        theme () { 
            this.chartInstance.dispose() //销毁图表
            this.initChart()  //重新构建图表
            this.screenAdapter() //自适应浏览器
            this.getData()
            // this.updateChart() //更新数据
        } 
    }
}
</script>

<style lang="scss" scoped>
    .title { 
        position: absolute; 
        left: 20px; 
        top: 20px; 
        z-index: 10; 
        color: white; 
        .title-icon { 
            margin-left: 10px; 
            cursor: pointer; }
        .select-item { 
            cursor: pointer; } 
        .select-con { 
            background-color: #222733; }
        .select-item { 
            cursor: pointer; }
    }
</style>

