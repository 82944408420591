<template>
  <div class="com-container">
    <div class="data_left03">
      <div class="dataAllNo01 maginS01">
        <div class="dataAllBorder01">
          <div class="dataAllBorder02 srcoll">
            <el-row class="tac">
              <el-col>
                <div class="motor-title">
                  <span>▌ 用水协会建设</span>
                </div>
                <el-menu default-active="1"
                         class="el-menu-vertical-demo"
                         background-color="transparent"
                         text-color="#fff">
                  <el-submenu index="1">
                    <template slot="title">
                      <span @click="getNew">用水协会建设规程</span>
                    </template>
                    <el-menu-item v-for="item in news.newInfo"  :index='item.id' :key="item.id">{{item.title}}</el-menu-item>
                  </el-submenu>
                  <el-submenu index="3">
                    <template slot="title">
                      <span @click="getWork">乡村用水协会组织架构</span>
                    </template>
                    <el-submenu index="1-5">
                      <template slot="title">荣河镇</template>
                      <el-menu-item v-for="item in work.newInfo" :index='item.id' :key="item.id" @click="change(item.id)">{{item.title}}</el-menu-item>
                    </el-submenu>
                  </el-submenu>
                </el-menu>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div class="data_left04">
      <div class="dataAllNo01">
        <div class="dataAllBorder01">
          <div class="dataAllBorder02 srcoll">
            <div v-show="show">
              <h2 align="center">{{news.title}}</h2>
              <li v-for="item in news.newInfo" :key="item.id">
                <h4>{{item.title}}</h4>
                <div v-html="item.info"></div>
              </li>
            </div>
            <div v-show="!show">
              <h2 align="center">{{village.title}}</h2>
              <div v-html="village.info"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
     news:'',
     work:'',
     village:'',
     show:true
    }
  },
  mounted () {
    this.getNew()
  },
  methods: {
    getNew(){
      this.show = true
      this.$axios.get('https://sxzdkj.top/www/news/12').then(ret=>{
        if (ret.data.code == 1000) {
          this.news = ret.data.data[0]
        }
      })
    },
    getWork(){
      this.show = false
       this.$axios.get('https://sxzdkj.top/www/news/13').then(ret=>{
        if (ret.data.code == 1000) {
          this.work = ret.data.data[0]
        }
      })
    },
    change(id){
      this.work.newInfo.forEach(element => {
        if (element.id == id) {
          this.village = element
        }
      });
    }
  }
}
</script>

<style lang="scss" >
.srcoll {
    overflow-y: auto;
}

</style>