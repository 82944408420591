<template>
  <div class="com-container">
    <div class="info">
      <div class="info-img">
        <el-image style="width: 200px; height: 130px"
                  :src="data.imgUrl"
                  fit="cover"></el-image>
        <div class="info-text-left">
          <p>机井名称：{{data.name}}</p>
          <p>机井编码：{{data.id}}</p>
          <p>机井地址：{{data.address}}</p>
        </div>
      </div>
      <div class="info-text-right">
        <p>机井用途：{{data.fun}}</p>
        <p>灌溉面积：{{data.nub}}</p>
        <p>灌溉农户：{{data.nubP}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  mounted () {
    this.getInfo('00500221091000022706')
  },
  methods: {
    async getInfo (id) {
      const { data: res } = await this.$axios.get('/static/json/wr.json')
      this.data = res[id]
    }
  }
}
</script>

<style lang="scss" scoped>
.com-container {
  margin-top: -28px;
  font-size: 18px;
  color: rgb(250, 243, 243);
}
.info {
  height: 100%;
  margin: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.info-img {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.info-text-left {
  margin-left: 40px;
}
p {
  margin-top: 8px !important;
}
</style>