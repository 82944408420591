import Vue from 'vue'
import VueRouter from 'vue-router'
import ScreenPage from '../views/ScreenPage'
import TemplatePage from '../views/TemplatePage'
import TemplatePageTwo from '../views/TemplatePageTwo'
import TemplatePageTh from '../views/TemplatePageTh'
import TemplatePageF from '../views/TemplatePageF'
import TableTest from '../views/TableTest'
import MqttTest from '../views/MqttTest'

import Log from '../views/Log'



Vue.use(VueRouter)

const routes = [
  {
    path: '/screenpage',
    name: 'screenpage',
    component: ScreenPage
  },
  {
    path: '/',
    name: 'templatepage',
    component: TemplatePage,
    meta:{requireAuth:true}
  },
  {
    path: '/templatepage2',
    name: 'templatepage2',
    component: TemplatePageTwo,
    meta:{requireAuth:true}
  },
  {
    path: '/templatepage3',
    name: 'templatepage3',
    component: TemplatePageTh,
    meta:{requireAuth:true}
  },
  {
    path: '/templatepage4',
    name: 'templatepage4',
    component: TemplatePageF,
    meta:{requireAuth:true}
  },

  {
    path: '/table',
    name: 'table',
    component: TableTest
  },
  {
    path: '/mqtt',
    name: 'mqtt',
    component: MqttTest
  },
  {
    path: '/log',
    name: 'log',
    component: Log
  },
]

const router = new VueRouter({
  routes
})

export default router
