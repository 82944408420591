<template>
  <div class="com-container"
       :key="show">
    <div id="video-container"
         style="width:100%;height:100%"
         ref='cam_ref'></div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";

export default {
  data () {
    return {
      player: null,
      accessToken: null,
      show: 0,
      camGyk: 'ezopen://open.ys7.com/E36181468/1.hd.live',
      camZmy: 'ezopen://open.ys7.com/E36181464/1.hd.live',
      testUrl: 'ezopen://open.ys7.com/C56870414/1.hd.live'
    }
  },
  // props: [url],
  mounted () {
    this.getToken().then(() => {
      this.initCam(600, 400)
    })
    window.addEventListener('resize', this.camAdapter)
    setTimeout(() => {
      this.player.stop(); // 方法调用示例，120秒后关闭视频
    }, 120000)
  },
  destroyed () {
    //在组件销毁的时候，停止播放
    this.player.stop()
  },
  methods: {
    async getToken () {
      const data = new FormData()
      data.append('appKey', '016a8f402c6a452a9672a9944194d85a')
      data.append('appSecret', '5e61e1db1c49a4b3e30bdcf14c3ef1ef')
      const res = await this.$axios.post('https://open.ys7.com/api/lapp/token/get', data)
      this.accessToken = res.data.data.accessToken
      return Promise.resolve(this.accessToken)
    },

    initCam (w, h) {
      this.player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "video-container",
        accessToken: this.accessToken,
        url: this.testUrl,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        width: w,
        height: h
      });
    },
    camAdapter () {

      const h = this.$refs.cam_ref.offsetHeight
      const w = this.$refs.cam_ref.offsetWidth
      // this.show++
      // this.$nextTick(() => {
      //   this.show = true
      // })
      this.initCam(w, h)
    }
  }

}

</script>

<style lang="scss" scoped>
</style>