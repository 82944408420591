<template>
    <div class="com-container">
        <div class="com-chart" ref="chart_ref"></div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            chartInstance:null,
            allDate:[
                {name:'商家1',value:22},
                {name:'商家2',value:128},
                {name:'商家3',value:23},
                {name:'商家4',value:132},
                {name:'商家5',value:42},
                {name:'商家6',value:52},
                {name:'商家7',value:62},
                {name:'商家8',value:142},
                {name:'商家9',value:152},
                {name:'商家10',value:162},
                {name:'商家11',value:182},
                {name:'商家12',value:152},
                {name:'商家13',value:162},
                {name:'商家14',value:182}
            ],
            countPage:1,
            totalPage:0,
            timeId:null
        }
    },
    mounted(){
        this.initChart()
        this.getData()
        //监听浏览器大小，发生变化时调用方法
        window.addEventListener('resize', this.screenAdapter)
        //在页面加载时，主动进行屏幕适配
        this.screenAdapter()
    },
    destroyed(){
        //在组件销毁的时候，清除定时器
        clearInterval(this.timeId) 
        // 在组件销毁的时候, 需要将监听器取消掉 
        window.removeEventListener('resize', this.screenAdapter)
    },
    methods:{
       //初始化图表对象
       initChart(){
           //第二个参数即时设置主题
          this.chartInstance = this.$echarts.init(this.$refs.chart_ref,'chalk')
          //拆分option，option可进行多次设置
          const setOption = {
              title:{
                    text:'▏商家销售大全',
                    // subtext: '这是备注，可以设置外网链接',
                    // sublink: 'https://www.baidu.com',
                    left:20,
                    top:20
                },
                //设置图表位置
                grid: {
                    left: '4%',
                    right: '4%',
                    top:'15%',
                    bottom: '3%',
                    //设置位置就包含坐标轴文字
                    containLabel: true
                },
                //工具提示和背景
                tooltip: { 
                    trigger: 'axis', 
                    axisPointer: { 
                        type: 'line', 
                        z: 0, 
                        lineStyle: { width: 66, color: '#2D3443' } 
                    }
                },
                xAxis: {
                    type: 'value' //vlaue在x/y不同，则柱状图呈现横/竖模式
                },
                yAxis: {            
                    type: 'category',
                },
                series: [{
                    type: 'bar',
                    showBackground: true,
                    //文字显示和位置
                    label: { show: true, position: 'right', textStyle: { color: '#fff' }},
                    //柱宽度和柱圆角的实现
                    // barWidth: 66, 监测屏幕变化进行第三次设置
                    itemStyle: { 
                        // barBorderRadius: [0, 33, 33, 0], 监测屏幕变化进行第三次设置
                        //柱颜色渐变的实现线性渐变可以通过 LinearGradient 进行实现LinearGradient 需要传递5个参数, 
                        // 前四个代表两个点的相对位置,第五个参数代表颜色变化的范围0, 0, 1, 0 代表的是从左往右的方向
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                             { offset: 0, color: '#5052EE' },
                             { offset: 1, color: '#AB6EE5' } ]) 
                    }
                }]
          }
          this.chartInstance.setOption(setOption)
          //对图表对象进行鼠标监听
          this.chartInstance.on('mouseover',()=>{
              clearInterval(this.timeId)
          })
          this.chartInstance.on('mouseout',()=>{
              this.startInterval()
          })
       },
       //获取服务器数据
       getData(){
           //数组排序
           this.allDate.sort((a,b)=>{
               return a.value - b.value
           })
           //计算数据是否能给整除，以或者总页数
           this.totalPage = this.allDate.length%5 === 0?this.allDate.length/5:this.allDate.length/5+1
           this.updateChart()
           this.startInterval()
       },
       //更新图表
       updateChart(){
           const start = (this.countPage-1)*5
           const end = this.countPage*5
           const showDate = this.allDate.slice(start,end)
           const sellerName = showDate.map((item)=>{
               return item.name
           })
           const sellerValue = showDate.map((item)=>{
               return item.value
           })
           const dateOption = {             
                yAxis: {            
                    data: sellerName
                },
                series: [{
                    data: sellerValue
                }]
           }
           this.chartInstance.setOption(dateOption)
       },
       startInterval(){
           if (this.timeId) {
               clearInterval(this.timeId)
           }
           this.timeId = setInterval(() => {
               this.countPage++
               if (this.countPage > this.totalPage) {
                   this.countPage = 1
               }
               this.updateChart()
           },3000)
       },
       screenAdapter(){
           //获取容器宽度，调整字体大小
           const titleFontSize = this.$refs.chart_ref.offsetWidth / 100 * 3.6
           const adapterOption = { 
               title: { 
                   textStyle: {
                        fontSize: titleFontSize 
                        } 
                },
               tooltip: { axisPointer: { lineStyle: { width: titleFontSize } }},
               series: [
                   { barWidth: titleFontSize, 
                   itemStyle: { barBorderRadius: [0, titleFontSize / 2, titleFontSize / 2, 0] } 
                   } 
                ] 
            }
            //第三次设置option
            this.chartInstance.setOption(adapterOption) 
            // 手动的调用图表对象的resize 才能产生效果 
            this.chartInstance.resize()
       } 
    }
}
</script>

<style>

</style>