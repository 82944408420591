<template>
  <div class="com-container srcoll">
    <el-row class="tac">
      <el-col>
        <div class="motor-title">
          <span>▌ 设备列表</span>
          <el-select v-model='value'
                     placeholder="选择乡镇"
                     @change="townChange">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-menu default-active='1'
                 text-color="#fff"
                 class="el-menu-vertical-demo"
                 background-color="transparent"
                 active-text-color="#ffd04b">
          <el-menu-item v-for="(item,i) in town"
                        :key="i"
                        :index='(i+1).toString()'>
            <i :class="item.online?'el-icon-success color':'el-icon-error'"></i>
            <span @click="changeValue(item.id)">{{item.name}}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: '荣河镇',
      town: []
    }
  },
  mounted () {
    this.getProject().then(() => {
      this.townChange(1)
    })

  },
  methods: {
    async getDevices (id) {
      const { data: res } = await this.$axios.post('https://openapi.mp.usr.cn/usrCloud/dev/getDevs', JSON.stringify({ groupId: id }))
      this.town = []
      res.data.dev.forEach(element => {
        const arr = {
          name: element.name,
          online: element.onlineStatus,
          id: element.sn
        }
        this.town.push(arr)
        // console.log(this.town)
      })
    },
    async getProject () {
      const { data: res } = await this.$axios.post('https://openapi.mp.usr.cn/usrCloud/dev/getDevGroups', JSON.stringify({ projectId: 93939, id: 0 }))
      this.options = []
      // console.log(res.data)
      res.data.groupList.forEach(element => {
        const arr = {
          value: element.weight,
          id: element.id,
          label: element.title
        }
        this.options.push(arr)
        // console.log(this.options)
      });
    },
    townChange (id) {
      id = id - 1
      const num = this.options[id].id
      this.getDevices(num)
    },
    changeValue (id) {
      this.$emit('change', id)
    }
  },
  watch: {
  }
}

</script>

<style lang="scss" scoped>
.com-container {
  font-size: 20px;
  color: rgb(250, 243, 243);
}
.srcoll {
    overflow-y: auto;
}
.color {
  color: #33a2ef;
}
.motor-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-row {
  margin: 5px;
  .el-col-20 {
    width: 100%;
  }
}
.el-menu {
  border-right: solid 0px #e6e6e6;
  // background-color: rgba(0, 0, 0, 0);
  margin-top: 8px;
}

.el-menu-item {
  font-size: 16px;
  height: 45px;
  line-height: 45px;
  // color: #666666;
  transition: none !important;
}
//设置选中el-menu-item时的样式
.el-menu-item:hover {
  border-left: #33a2ef solid 6px !important;
  background-color: rgba($color: #000000, $alpha: 0.3) !important;
  color: #38b2ff !important;
  //less语法，实现鼠标悬停时icon变色
  i {
    color: #38b2ff;
  }
}
//设置选中el-menu-item时的样式
.el-menu-item.is-active {
  border-left: #33a2ef solid 6px !important;
  background-color: rgba($color: #000000, $alpha: 0.3) !important;
  color: #38b2ff !important;
}
/deep/.el-input--suffix .el-input__inner {
  width: 120px;
  background-color: rgba($color: #000000, $alpha: 0) !important;
  padding-right: 5px;
  height: 30px;
  line-height: 30px;
}
.el-popper {
  background-color: rgba($color: #000000, $alpha: 0) !important;
}
</style>