<template>
  <div>
      <tab></tab>
  </div>
</template>

<script>
import Tab from '../components/Tab.vue'
export default {
  components: { Tab },

}
</script>

<style>

</style>