<template>
  <div class="com-container">
    <div class="com-chart"
         ref="pie_ref"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartInstance: null,
      // allData:null,
      // dataType: 'map',
      // showChoice: false,
      // titleFontSize: 0
    }
  },
  mounted () {
    this.initChart()
    this.getData()
    //监听浏览器大小，发生变化时调用方法
    window.addEventListener('resize', this.screenAdapter)
    //在页面加载时，主动进行屏幕适配
    this.screenAdapter()
  },
  methods: {
    //初始化图表对象
    initChart () {
      //第二个参数即时设置主题
      this.chartInstance = this.$echarts.init(this.$refs.pie_ref, "macarons")
      //拆分option，option可进行多次设置
      const initOption = {
        title: {
          text: '▌ 设备概况',
          textStyle: {//标题字体颜色等设置
            fontSize: 20,
            // fontWeight: 'bold',
            color: 'white'
          },
          left: 2,
          top: 5
        },
        tooltip: {
          trigger: 'item',
          left: 20
        },
        legend: {
          top: '35%',
          left: 20,
          orient: 'vertical',
          itemGap: 36,
          textStyle: {//属性名的字体样式设置
            fontSize: 15,
            color: 'white',
            fontWeight: 'bold'
          }
        },
        series: [
          {
            name: '设备概况',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['70%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },

            labelLine: {
              show: false
            },

          }
        ]
      }
      this.chartInstance.setOption(initOption)
    },
    async getData () {
      const { data: res } = await this.$axios.post('https://openapi.mp.usr.cn/usrCloud/dev/getDevs', JSON.stringify({ searchByDeviceStatus: 1 }))
      const dataOption = {
        series: [
          {
            data: [
              { value: res.data.total, name: '在线设备' },
              { value: 18 - res.data.total, name: '离线设备' },
            ]
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
      // this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      //获取容器宽度，调整字体大小
      this.titleFontSize = this.$refs.pie_ref.offsetWidth / 100 * 3.6
      const adapterOption = {
        legend: {
          // itemWidth: this.titleFontSize,
          // itemHeight: this.titleFontSize,
          // itemGap: this.titleFontSize,
          // textStyle: { fontSize: this.titleFontSize / 2 }
        }
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    }
  },

}
</script>

<style lang="scss" scoped>
.com-container {
  font-size: 20px;
  color: white;
}
</style>