<template>
<!-- 水权交易平台页面 -->
  <div class="data_bodey">
    <div class="index_nav">
      <ul style="height: 30px; margin-bottom: 0px;">
        <router-link to="/"
                     exact>
          <li class="l_left total_chose_fr ">实时监测</li>
        </router-link>
        <router-link to="/templatepage2">
          <li class="l_left total_chose_pl">用水协会建设</li>
        </router-link>
        <router-link to="/templatepage3">
          <li class="r_right total_chose_pl">水权分配报告</li>
        </router-link>
        <router-link to="/templatepage4">
          <li class="r_right total_chose_pl">水权交易平台</li>
        </router-link>
      </ul>
    </div>
    <div class="index_tabs">
      <div class="inner"
           style="height: 100%;">
        <ul style="height: 30px; margin-bottom: 0px;">
        </ul>
        <!-- <div class="total_chose_box">
          <div style="height: 32px;"></div>
        </div> -->
        <div class="clear"></div>
        <iframe src="http://www.cwex.org.cn/publiccms/webfile/index.html"
                frameborder="0"
                class="jy"
                ref="jy_ref"></iframe>
      </div>
    </div>

  </div>
</template>

<script>
import '../assets/scss/index.css'
import '../assets/scss/index01.css'
import '../assets/scss/Security_operation.css'
import '../assets/scss/BigData.css'
export default {
  mounted () {
    const oIframe = this.$refs.jy_ref;
    // const deviceWidth = document.documentElement.clientWidth;
    // const deviceHeight = document.documentElement.clientHeight;
    // oIframe.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
    // oIframe.style.height = (Number(deviceHeight)) + 'px'; //数字是页面布局高度差
    console.log(oIframe.contentWindow.document)
    var y = (oIframe.contentWindow || oIframe.contentDocument);
    if (y.document) y = y.document;
    console.log(y.body)
    // y.body.style.backgroundColor = "#f9f9f9";
    // y.body.style.fontSize = 30;
    // console.log(y.body.style)

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.jy {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 66px;
  left: 0;
  background-color: #f2f6fc;
}
</style>