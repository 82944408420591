<template>
  <div class="com-container">
    <el-button></el-button>
    <div class="map"
         ref="map_ref">
      <baidu-map :center="center"
                 class="baidumap"
                 @ready='handler'
                 :scroll-wheel-zoom='true'
                 :zoom='zoom'
                 mapType="BMAP_SATELLITE_MAP">
        <!-- name 表示区域(行政区)的名字，例如上海市黄浦区，北京市朝阳区
                strokeWeight 表示区域边界的边框宽度
                strokeColor 表示区域边界的边框颜色
                fillColor 表示区域边界的填充颜色
                fillOpacity 表示区域边界的填充颜色透明度 -->
        <bm-boundary name='稷山'
                     :strokeWeight="2"
                     strokeColor="blue"
                     fillColor="skyblue"
                     :fillOpacity="0.1"></bm-boundary>
        <div v-for="(item,index) in content"
             :key="item.id">
          <bm-marker :position="{lng:item.lng, lat:item.lat}"
                     :dragging="false"
                     animation=""
                     @click="infoWindowOpen(index)"
                     :icon='{url:iconUrl.url,size:{width:32,height:32}}'>
            <bm-label :content="item.dot"
                      :offset="{width: -35, height: 30}" />
            <!-- 弹出框内容,可以添加图片 -->
            <bm-info-window :show="isShow == index"
                            @close="infoWindowClose(index)"
                            @open="infoWindowOpen(index)">
              <p>站点编码：{{item.id}}</p>
              <p>站点名称：{{item.dot}}</p>
              <p>负责人员：{{item.name}}</p>
              <p>联系电话：{{item.phone}}</p>
            </bm-info-window>
          </bm-marker>
        </div>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
// import transformSheets from '../assets/js/utils'
export default {
  data () {
    return {
      // dotList: null,
      center: '稷山',
      zoom: 12,
      isShow: -1,
      content: null,
      dotList: [
      ],
      iconUrl: { url: require('../assets/img/mark1.png') },
      exlUrl: '/static/exl/test.xls' //放在public目录下的文件可以直接访问
    }
  },
  mounted () {
    this.readExl(this.exlUrl)
  },
  methods: {
    infoWindowClose () {
      this.isShow = -1
    },
    infoWindowOpen (id) {
      this.isShow = id
      this.zoom = 13
      this.center = {
        lng: this.dotList[id].lng,
        lat: this.dotList[id].lat
      }
    },
    handler ({ map }) {
      let mapStyle = { style: 'midnight' }
      map.setMapStyle(mapStyle)
    },
    // async handle (ev) {
    //   // const file = require.context('../assets/xsxl/test.xls', false, /.xls$/);
    //   let file = ev.raw;
    //   if (!file) return;
    //   let data = await readFile(file);
    //   let workbook = xlsx.read(data, { type: 'binary' });
    //   console.log(workbook)
    // },
    async readExl (url) {
      var data = await this.$axios.get(url, { responseType: 'arraybuffer' })
      var wb = XLSX.read(data.data, { type: "array" })
      let ws = wb.Sheets[wb.SheetNames[0]]
      this.content = XLSX.utils.sheet_to_json(ws)
    }
  }
}
</script>

<style lang="scss" >
.com-container {
  border-radius: 8px;
}
.baidumap {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.map {
  .BMap_bubble_title {
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
    border-bottom: 1px solid gray;
    background-color: #0066b3;
  }
  .BMap_pop {
    width: 300px;
    height: 200px;
  }
  /* 消息内容 */
  .BMap_bubble_content {
    // background-color: rgba($color: #0066b3, $alpha: 1);
    padding-left: 5px;
    // padding-left: 5px;
    // padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    box-shadow: 0 0 12vw #237ad4 inset;
    background: linear-gradient(#1359df, #1359df) left top,
      linear-gradient(#1359df, #1359df) left top,
      linear-gradient(#1359df, #1359df) right top,
      linear-gradient(#1359df, #1359df) right top,
      linear-gradient(#1359df, #1359df) left bottom,
      linear-gradient(#1359df, #1359df) left bottom,
      linear-gradient(#1359df, #1359df) right bottom,
      linear-gradient(#1359df, #1359df) right bottom;
    background-repeat: no-repeat;
    background-size: 0.1vw 18vw, 1.5vw 0.1vw;
    margin: 5px;
    img {
      width: 99%;
      height: 90%;
      margin-right: 5px;
    }
    p {
      width: 210px;
      margin-left: 10px !important ;
      font-size: 16px !important;
    }
  }
  /* 内容 */
  .BMap_pop div:nth-child(9) {
    top: 35px !important;

    // border-radius: 7px;
  }
  /* 左上角删除按键 */
  .BMap_pop img {
    top: 43px !important;
    left: 220px !important;
  }
  .BMap_top {
    display: none;
  }
  .BMap_bottom {
    display: none;
  }
  .BMap_center {
    display: none;
  }
  /* 隐藏边角 */
  .BMap_pop div:nth-child(1) div {
    display: none;
  }
  .BMap_pop div:nth-child(3) {
    display: none;
  }
  .BMap_pop div:nth-child(7) {
    display: none;
  }
  .BMap_pop div:nth-child(5) {
    display: none;
  }
  .BMap_pop div:nth-child(8) {
    display: none;
  }
}
</style>

